<script setup lang="ts">
import Callout from "@/components/utils/Callout.vue"
import ClientOnlyCalculator from "@/components/Calculator/ClientOnlyCalculator.vue"
import useAosRefresh from "@/composables/useAosRefresh";
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});

useAosRefresh()
</script>

<template>

  <div class="flex flex-col items-center container">
    <Callout
      data-aos
      class="duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5)"
      icon="i-hugeicons:calculate"
    >
      {{ t('simulate_based_on_past_performances') }}
    </Callout>
    <div data-aos class="heading-2 leading-[150%] text-center mt-3 duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-200">
      {{ t('calculate_your_earnings')}}
    </div>
    <div class="relative">
      <p 
        data-aos 
        :class="[
          'mt-5 leading-[150%] text-brand-secondary max-w-153 text-center', 
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-400'
        ]"
      >
        {{t('calculate_your_earnings_desc')}}
      </p>
      <!-- ligthing effect start -->
      <div 
        :class="[
          'bg-effect-lighting bg-brand-gradient -z-5',
          'absolute top-0 left-1/2 -translate-x-1/2 lt-sm:translate-y-2/5',
          'aspect-video w-full lg:w-[125%]',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-1200'
        ]"
        data-aos 
      />
      <!-- ligthing effect end -->
    </div> 
    <ClientOnlyCalculator class="mt-8.5 w-full" />
  </div>

</template>