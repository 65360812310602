export const urlmap = {
  "en": {
    "ru": {
      "blog": "blog/ru",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/ru/blog/chto-delaet-khoroshego-treynding-bota-khoroshim",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/ru/blog/shpargalka-po-svechnym-grafikam-dlya-treiderov-kriptovalyut",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/ru/blog/otkroite-dlya-sebya-silu-dipsway-ai-bota-dlya-uspeshnoy-kriptovalyutnoy-torgovli",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/ru/blog/pochemu-kriptovalyuta-padayet-i-vosstanovitsya-li-ona-v-blizhayshem-budushchem",
      "/en/blog/coin-and-token-difference": "/ru/blog/raznitsa-mezhdu-monetoy-i-tokenom",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/ru/blog/dipsway-i-bitget-obedinyayutsya-uznayte-kak-podklyuchit-bitget-i-vyigrat-nagrady",
      "/en/blog/dipsway-new-platform": "/ru/blog/dipsway-novaya-platforma",
      "/en/blog/dipsway-vs-coinrule": "/ru/blog/dipsway-protiv-coinrule",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/ru/blog/obyasnil-kontseptsiyu-spotovoy-tseny-i-eyo-znachimost",
      "/en/blog/feature-selective-sell": "/ru/blog/funktsiya-vyborochnaya-prodazha",
      "/en/blog/feature-stop-loss-&-take-profit": "/ru/blog/funktsiya-stop-loss-i-teyk-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/ru/blog/kak-rabotaet-kripto-bot",
      "/en/blog/how-to-connect-binance-api-keys": "/ru/blog/kak-podklyuchit-api-klyuchi-binance",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/ru/blog/kak-podklyuchit-bingx-k-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/ru/blog/kak-podklyuchit-klyuchi-api-bitmart",
      "/en/blog/how-to-connect-bybit-api-keys": "/ru/blog/kak-podklyuchit-klyuchi-api-bybit",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/ru/blog/kak-podklyuchit-klyuchi-api-coinbase-advanced",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/ru/blog/kak-podklyuchit-kraken-klyuchi-api-starogo-obraztsa",
      "/en/blog/how-to-connect-kucoin-api-keys": "/ru/blog/kak-podklyuchit-api-klyuchi-kucoin",
      "/en/blog/how-to-connect-okx-api-keys": "/ru/blog/kak-podklyuchit-okx-api-klyuchi",
      "/en/blog/how-to-optimize-your-ai-bot": "/ru/blog/kak-optimizirovat-vashego-ai-bota",
      "/en/blog/risk-management-with-dipsway-bot": "/ru/blog/upravlenie-riskami-s-pomoshchyu-bota-dipsway",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/ru/blog/bot-dlya-analiza-nastroeniy-v-kriptovalyutnoy-torgovle",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/ru/blog/uproshchenie-spotovykh-kontraktov-vvedenie-dlya-treiderov",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/ru/blog/bot-binance-grid-vse-chto-vam-nuzhno-znat",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/ru/blog/koshelek-spot-polnoe-rukovodstvo",
      "/en/blog/website-redesign": "/ru/blog/redizayn-veb-sayta",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/ru/blog/chto-takoe-spisok-razreshennykh-ip-adresov-i-kak-on-pomogaet-vam-byt-boleye-zashchishchennymi",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/ru/blog/chto-luchshe-dlya-avtomaticheskoy-torgovli-kriptovalyutami-dipsway-ili-3commas",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/ru/blog/pochemu-dipsway-yavlyaetsya-luchshey-platformoy-dlya-kriptotreiderov-luchshiy-vybor-kak-dlya-nachinayushchikh-tak-i-dlya-professionalov",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/ru/blog/pochemu-vam-nuzhen-ii-v-vashem-kripto-bote",
      "/en/blog/you-re-safe-with-dipsway": "/ru/blog/ty-v-bezopasnosti-s-dipsveem",
      "/en/blog/coinrule-alternatives": "/ru/blog/альтернативы-coinrule",
      "/en/blog/3commas-alternatives": "/ru/blog/3commas-alternativy",
      "/en/blog/ai-crypto-bot-setup-status": "/ru/blog/ai-crypto-bot-nastrojka-status",
      "/en/blog/altcoins-trading": "/ru/blog/altcoins-trading-ru",
      "/en/blog/binance-bots-alternatives": "/ru/blog/binance-bots-alternativy",
      "/en/blog/bitsgap-alternatives": "/ru/blog/bitsgap-alternativy",
      "/en/blog/bybit-bots-alternatives": "/ru/blog/bybit-bots-alternativy",
      "/en/blog/cornix-alternatives": "/ru/blog/encornix-alternativy",
      "/en/blog/cryptohopper-alternatives": "/ru/blog/encryptohopper-alternativy",
      "/en/blog/pionex-alternatives": "/ru/blog/pionex-alternativy",
      "/en/blog/shrimpy-alternatives": "/ru/blog/shrimpy-alternatives-ru",
      "/en/blog/tradesanta-alternatives": "/ru/blog/tradesanta-alternativy",
      "/en/blog/wundertrading-alternatives": "/ru/blog/wundertrading-alternativy",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/ru/blog/лучшие-альткоины-для-торговли-в-2024-году-лучшие-выборы-для-крипто-инвесторов",
      "/en/blog/mev-bot": "/ru/blog/mev-bot-ru",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/ru/blog/chto-takoe-ethereum-etf-i-kak-v-nego-investirovat",
      "/en/blog/will-crypto-crash": "/ru/blog/budet-li-kripto-krakh",
      "/en/blog/bitcoin-converter": "/ru/blog/ru-bitcoin-konverter",
      "/en/blog/ai-trading-bots": "/ru/blog/ai-trading-bots-ru",
      "/en/blog/euri-stablecoin": "/ru/blog/euri-stablecoin-ru",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/ru/blog/trade-trump-coin-s-kripto-botom",
      "/en/blog/trump-coin": "/ru/blog/trump-coin-ru"
    },
    "it": {
      "/en/blog/3commas-alternatives": "/it/blog/3commas-alternative",
      "/en/blog/ai-crypto-bot-setup-status": "/it/blog/ai-crypto-bot-configurazione-stato",
      "/en/blog/altcoins-trading": "/it/blog/altcoins-trading-it",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/it/blog/migliori-altcoin-da-scambiare-nel-2024-scelte-migliori-per-investitori-cripto",
      "/en/blog/binance-bots-alternatives": "/it/blog/alternative-bot-binance",
      "/en/blog/bitsgap-alternatives": "/it/blog/alternative-bitsgap",
      "/en/blog/bybit-bots-alternatives": "/it/blog/bybit-bot-alternative",
      "/en/blog/coin-and-token-difference": "/it/blog/differenza-tra-moneta-e-token",
      "/en/blog/coinrule-alternatives": "/it/blog/coinrule-alternative",
      "/en/blog/cornix-alternatives": "/it/blog/alternative-cornix",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/it/blog/cheat-sheet-candele-cripto-per-trader",
      "/en/blog/cryptohopper-alternatives": "/it/blog/alternative-a-cryptohopper",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/it/blog/dipsway-e-bitget-collaborano-scopri-come-connettere-bitget-vinci-premi",
      "/en/blog/dipsway-new-platform": "/it/blog/dipsway-nuova-piattaforma",
      "/en/blog/dipsway-vs-coinrule": "/it/blog/dipsway-vs-coinrule-it",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/it/blog/abbraccia-il-potere-del-bot-ai-di-dipsway-per-il-successo-nel-trading-di-criptovalute",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/it/blog/spiegato-il-concetto-di-prezzo-spot-e-la-sua-importanza",
      "/en/blog/feature-selective-sell": "/it/blog/caratteristica-selettiva-vendita",
      "/en/blog/feature-stop-loss-&-take-profit": "/it/blog/funzionalità-stop-loss-e-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/it/blog/come-funziona-un-bot-crypto",
      "/en/blog/how-to-connect-binance-api-keys": "/it/blog/come-collegare-chiavi-api-binance",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/it/blog/come-collegare-bingx-a-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/it/blog/come-collegare-chiavi-api-bitmart",
      "/en/blog/how-to-connect-bybit-api-keys": "/it/blog/come-collegare-chiavi-api-bybit",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/it/blog/come-collegare-chiavi-api-avanzate-coinbase",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/it/blog/come-collegare-chiavi-api-legacy-kraken",
      "/en/blog/how-to-connect-kucoin-api-keys": "/it/blog/come-collegare-chiavi-api-kucoin",
      "/en/blog/how-to-connect-okx-api-keys": "/it/blog/come-collegare-chiavi-api-okx",
      "/en/blog/how-to-optimize-your-ai-bot": "/it/blog/come-ottimizzare-il-tuo-bot-ai",
      "/en/blog/mev-bot": "/it/blog/mev-bot-it",
      "/en/blog/pionex-alternatives": "/it/blog/alternative-a-pionex",
      "/en/blog/risk-management-with-dipsway-bot": "/it/blog/gestione-del-rischio-con-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/it/blog/sentiment-analysis-bot-nel-trading-crypto",
      "/en/blog/shrimpy-alternatives": "/it/blog/alternative-di-gamberetti",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/it/blog/semplificare-contratti-spot-introduzione-per-trader",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/it/blog/il-bot-a-griglia-di-binance-tutto-quello-che-devi-sapere",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/it/blog/la-guida-completa-al-portafoglio-spot",
      "/en/blog/tradesanta-alternatives": "/it/blog/alternative-tradesanta",
      "/en/blog/website-redesign": "/it/blog/ridisegno-sito-web",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/it/blog/cosaeunetfdiethereumecomepuoiinvestirci",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/it/blog/cosè-lista-consentiti-ip-e-come-ti-aiuta-essere-più-sicuro",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/it/blog/cosa-rende-un-buon-bot-di-trading-buono",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/it/blog/quale-e-migliore-dipsway-o-3commas-per-trading-automatico-di-cripto",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/it/blog/perche-dipsway-e-la-piattaforma-ultima-per-crypto-trader-scelta-top-per-principianti-e-professionisti-allo-stesso-modo",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/it/blog/perche-crypto-sta-crollando-e-si-riprendera-nel-prossimo-futuro",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/it/blog/perche-hai-bisogno-di-ai-nel-tuo-bot-crypto",
      "/en/blog/wundertrading-alternatives": "/it/blog/alternative-a-wundertrading",
      "/en/blog/you-re-safe-with-dipsway": "/it/blog/sei-al-sicuro-con-dipsway",
      "/en/blog/will-crypto-crash": "/it/blog/crollo-cripto",
      "/en/blog/bitcoin-converter": "/it/blog/convertitore-bitcoin",
      "/en/blog/ai-trading-bots": "/it/blog/ai-trading-bot",
      "/en/blog/euri-stablecoin": "/it/blog/euri-stablecoin-it",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/it/blog/commercia-trump-coin-con-crypto-bot",
      "/en/blog/trump-coin": "/it/blog/trump-moneta"
    },
    "es": {
      "/en/blog/3commas-alternatives": "/es/blog/alternativas-a-3commas",
      "/en/blog/ai-crypto-bot-setup-status": "/es/blog/estado-configuracion-bot-cripto-ai",
      "/en/blog/altcoins-trading": "/es/blog/altcoins-trading-es",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/es/blog/mejores-altcoins-para-comerciar-en-2024-mejores-elecciones-para-inversores-cripto",
      "/en/blog/binance-bots-alternatives": "/es/blog/alternativas-a-los-bots-de-binance",
      "/en/blog/bitsgap-alternatives": "/es/blog/alternativas-a-bitsgap",
      "/en/blog/bybit-bots-alternatives": "/es/blog/alternativas-a-los-bots-de-bybit",
      "/en/blog/coin-and-token-difference": "/es/blog/diferencia-entre-moneda-y-token",
      "/en/blog/coinrule-alternatives": "/es/blog/coinrule-alternativas",
      "/en/blog/cornix-alternatives": "/es/blog/alternativas-de-cornix",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/es/blog/hoja-de-trucos-de-velas-cripto-para-comerciantes",
      "/en/blog/cryptohopper-alternatives": "/es/blog/alternativas-a-cryptohopper",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/es/blog/dipsway-y-bitget-se-unen-aprende-como-conectar-bitget-gana-recompensas",
      "/en/blog/dipsway-new-platform": "/es/blog/dipsway-nueva-plataforma",
      "/en/blog/dipsway-vs-coinrule": "/es/blog/dipsway-vs-coinrule-es",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/es/blog/abraza-el-poder-del-bot-de-ai-de-dipsway-para-el-comercio-exitoso-de-cripto",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/es/blog/explicado-el-concepto-de-precio-spot-y-su-significado",
      "/en/blog/feature-selective-sell": "/es/blog/característica-selectiva-vender",
      "/en/blog/feature-stop-loss-&-take-profit": "/es/blog/caracteristica-stop-loss-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/es/blog/como-funciona-un-bot-cripto",
      "/en/blog/how-to-connect-binance-api-keys": "/es/blog/como-conectar-claves-api-binance",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/es/blog/como-conectar-bingx-a-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/es/blog/como-conectar-claves-api-bitmart",
      "/en/blog/how-to-connect-bybit-api-keys": "/es/blog/como-conectar-claves-api-de-bybit",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/es/blog/como-conectar-claves-api-avanzadas-de-coinbase",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/es/blog/como-conectar-claves-api-legado-kraken",
      "/en/blog/how-to-connect-kucoin-api-keys": "/es/blog/como-conectar-claves-api-kucoin",
      "/en/blog/how-to-connect-okx-api-keys": "/es/blog/como-conectar-claves-api-okx",
      "/en/blog/how-to-optimize-your-ai-bot": "/es/blog/como-optimizar-tu-bot-ai",
      "/en/blog/mev-bot": "/es/blog/mev-bot-es",
      "/en/blog/pionex-alternatives": "/es/blog/alternativas-a-pionex",
      "/en/blog/risk-management-with-dipsway-bot": "/es/blog/gestion-de-riesgos-con-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/es/blog/analisis-de-sentimientos-bot-en-comercio-de-criptomonedas",
      "/en/blog/shrimpy-alternatives": "/es/blog/alternativas-de-camarones",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/es/blog/simplificando-contratos-spot-una-introduccion-para-comerciantes",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/es/blog/el-bot-de-rejilla-de-binance-todo-lo-que-necesitas-saber",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/es/blog/la-cartera-spot-una-guia-completa",
      "/en/blog/tradesanta-alternatives": "/es/blog/alternativas-a-tradesanta",
      "/en/blog/website-redesign": "/es/blog/rediseño-de-sitio-web",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/es/blog/que-es-un-etf-de-ethereum-y-como-puedes-invertir-en-el",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/es/blog/que-es-la-lista-de-permisos-de-ip-y-como-te-ayuda-a-ser-mas-seguro",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/es/blog/que-hace-un-buen-bot-de-trading-ser-bueno",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/es/blog/cual-es-mejor-dipsway-o-3commas-para-comercio-cripto-automatizado",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/es/blog/por-que-dipsway-es-la-plataforma-ultima-para-comerciantes-de-cripto-eleccion-principal-para-principiantes-y-profesionales-por-igual",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/es/blog/por-que-esta-colapsando-cripto-y-se-recuperara-en-el-futuro-cercano",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/es/blog/por-que-necesitas-ia-en-tu-bot-de-cripto",
      "/en/blog/wundertrading-alternatives": "/es/blog/alternativas-a-wundertrading",
      "/en/blog/you-re-safe-with-dipsway": "/es/blog/estas-seguro-con-dipsway",
      "/en/blog/will-crypto-crash": "/es/blog/se-estrellara-cripto",
      "/en/blog/bitcoin-converter": "/es/blog/convertidor-de-bitcoin",
      "/en/blog/ai-trading-bots": "/es/blog/bots-de-comercio-ai",
      "/en/blog/euri-stablecoin": "/es/blog/euri-stablecoin-es",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/es/blog/comercia-moneda-trump-con-bot-cripto",
      "/en/blog/trump-coin": "/es/blog/trump-moneda"
    },
    "ko": {
      "/en/blog/3commas-alternatives": "/ko/blog/3commas-daeryang",
      "/en/blog/ai-crypto-bot-setup-status": "/ko/blog/ai-crypto-bot-setup-status-ko",
      "/en/blog/altcoins-trading": "/ko/blog/알트코인-트레이딩",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/ko/blog/2024년-거래할-최고의-알트코인-암호화폐-투자자를-위한-최고의-선택",
      "/en/blog/binance-bots-alternatives": "/ko/blog/바이낸스-봇-대안",
      "/en/blog/bitsgap-alternatives": "/ko/blog/bitsgap-daeryang",
      "/en/blog/bybit-bots-alternatives": "/ko/blog/bybit-bots-daechacheo",
      "/en/blog/coin-and-token-difference": "/ko/blog/코인-토큰-차이점",
      "/en/blog/coinrule-alternatives": "/ko/blog/coinrule-daehangsae",
      "/en/blog/cornix-alternatives": "/ko/blog/cornix-alternatives-ko",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/ko/blog/크립토-캔들스틱-치트-시트-포-트레이더스",
      "/en/blog/cryptohopper-alternatives": "/ko/blog/크립토호퍼-대안",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/ko/blog/딥스웨이앤비트겟팀업배우기비트겟연결보상받기",
      "/en/blog/dipsway-new-platform": "/ko/blog/딥스웨이-뉴-플랫폼",
      "/en/blog/dipsway-vs-coinrule": "/ko/blog/dipsway-vs-coinrule-ko",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/ko/blog/딥스웨이의-ai-봇의-힘을-받아들여-성공적인-암호화폐-거래",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/ko/blog/설명한-현물-가격-개념-및-의의",
      "/en/blog/feature-selective-sell": "/ko/blog/특징선택적판매",
      "/en/blog/feature-stop-loss-&-take-profit": "/ko/blog/ko-feature-stop-loss-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/ko/blog/crypto-bot-eotteoke-jagdonghaneunji",
      "/en/blog/how-to-connect-binance-api-keys": "/ko/blog/binance-api-키-연결-방법",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/ko/blog/bingx-연결-방법-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/ko/blog/bitmart-api-키-연결-방법",
      "/en/blog/how-to-connect-bybit-api-keys": "/ko/blog/bybit-api-키-연결-방법",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/ko/blog/coinbase-고급-api-키-연결-방법",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/ko/blog/kraken-레거시-api-키-연결-방법",
      "/en/blog/how-to-connect-kucoin-api-keys": "/ko/blog/kucoin-api-키-연결-방법",
      "/en/blog/how-to-connect-okx-api-keys": "/ko/blog/okx-api-키-연결-방법",
      "/en/blog/how-to-optimize-your-ai-bot": "/ko/blog/ai-봇-최적화-방법",
      "/en/blog/mev-bot": "/ko/blog/mev-bot-ko",
      "/en/blog/pionex-alternatives": "/ko/blog/pionex-daeyan",
      "/en/blog/risk-management-with-dipsway-bot": "/ko/blog/위험-관리-디프스웨이-봇",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/ko/blog/감정분석봇암호화폐거래",
      "/en/blog/shrimpy-alternatives": "/ko/blog/shrimpy-daechacheo",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/ko/blog/단순화된-현물-계약-소개-트레이더-위한",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/ko/blog/binance-grid-bot-모든-것-알아야-할-사항",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/ko/blog/the-spot-wallet-a-comprehensive-guide-ko",
      "/en/blog/tradesanta-alternatives": "/ko/blog/트레이드산타-대안",
      "/en/blog/website-redesign": "/ko/blog/웹사이트-리디자인",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/ko/blog/이더리움-etf-란-무엇이며-어떻게-투자할-수-있나요",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/ko/blog/ip-허용목록이란-무엇이며-어떻게-더-안전하게-도와주는가",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/ko/blog/좋은-트레이딩-봇이-좋은이유",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/ko/blog/dipsway-vs-3commas-자동-암호화폐-거래-비교",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/ko/blog/왜-dipsway-가-최고의-플랫폼-인가-암호화폐-거래자-초보자-전문가-모두의-최고-선택",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/ko/blog/crypto-왜-폭락-하고-있으며-가까운-미래에-회복될-것인가",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/ko/blog/ai가-필요한-암호화-봇-이유",
      "/en/blog/wundertrading-alternatives": "/ko/blog/wundertrading-daehan-saneob",
      "/en/blog/you-re-safe-with-dipsway": "/ko/blog/너는-딥스웨이와-안전해",
      "/en/blog/will-crypto-crash": "/ko/blog/크립토-폭락할까",
      "/en/blog/bitcoin-converter": "/ko/blog/비트코인-컨버터",
      "/en/blog/ai-trading-bots": "/ko/blog/ai-trading-bots-ko",
      "/en/blog/euri-stablecoin": "/ko/blog/euri-seuteibeul-coin",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/ko/blog/트레이드-트럼프-코인-위드-크립토-봇",
      "/en/blog/trump-coin": "/ko/blog/트럼프-코인"
    },
    "de": {
      "/en/blog/3commas-alternatives": "/de/blog/3commas-alternativen",
      "/en/blog/ai-crypto-bot-setup-status": "/de/blog/ai-krypto-bot-setup-status",
      "/en/blog/altcoins-trading": "/de/blog/de-altcoins-handel",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/de/blog/beste-altcoins-zum-handeln-2024-top-tipps-fuer-krypto-investoren",
      "/en/blog/binance-bots-alternatives": "/de/blog/binance-bots-alternativen",
      "/en/blog/bitsgap-alternatives": "/de/blog/bitsgap-alternativen",
      "/en/blog/bybit-bots-alternatives": "/de/blog/bybit-bots-alternativen",
      "/en/blog/coin-and-token-difference": "/de/blog/münze-und-token-unterschied",
      "/en/blog/coinrule-alternatives": "/de/blog/coinrule-alternativen",
      "/en/blog/cornix-alternatives": "/de/blog/cornix-alternativen",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/de/blog/crypto-kerzenstaebchen-spickzettel-fuer-haendler",
      "/en/blog/cryptohopper-alternatives": "/de/blog/cryptohopper-alternativen",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/de/blog/dipsway-und-bitget-arbeiten-zusammen-erfahren-sie-wie-sie-bitget-verbinden-und-belohnungen-gewinnen",
      "/en/blog/dipsway-new-platform": "/de/blog/dipsway-neue-plattform",
      "/en/blog/dipsway-vs-coinrule": "/de/blog/dipsway-vs-coinrule-de",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/de/blog/umarmen-sie-die-kraft-von-dipsways-ai-bot-fuer-erfolgreiches-krypto-trading",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/de/blog/erklart-das-konzept-des-spotpreises-und-seine-bedeutung",
      "/en/blog/feature-selective-sell": "/de/blog/merkmalselektivverkaufen",
      "/en/blog/feature-stop-loss-&-take-profit": "/de/blog/feature-stop-loss-und-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/de/blog/wie-funktioniert-ein-krypto-bot",
      "/en/blog/how-to-connect-binance-api-keys": "/de/blog/wie-man-binance-api-schlüssel-verbindet",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/de/blog/wie-man-bingx-mit-dipsway-verbindet",
      "/en/blog/how-to-connect-bitmart-api-keys": "/de/blog/wie-man-bitmart-api-schlüssel-verbindet",
      "/en/blog/how-to-connect-bybit-api-keys": "/de/blog/wie-man-bybit-api-schlüssel-verbindet",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/de/blog/wie-man-coinbase-erweiterte-api-schluessel-verbindet",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/de/blog/wie-man-kraken-legacy-api-schlüssel-verbindet",
      "/en/blog/how-to-connect-kucoin-api-keys": "/de/blog/wie-man-kucoin-api-schlüssel-verbindet",
      "/en/blog/how-to-connect-okx-api-keys": "/de/blog/wie-man-okx-api-schlüssel-verbindet",
      "/en/blog/how-to-optimize-your-ai-bot": "/de/blog/wie-man-seinen-ai-bot-optimiert",
      "/en/blog/mev-bot": "/de/blog/mev-bot-de",
      "/en/blog/pionex-alternatives": "/de/blog/pionex-alternativen",
      "/en/blog/risk-management-with-dipsway-bot": "/de/blog/risikomanagement-mit-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/de/blog/sentimentanalyse-bot-im-krypto-handel",
      "/en/blog/shrimpy-alternatives": "/de/blog/shrimpy-alternativen",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/de/blog/vereinfachung-von-spot-vertraegen-eine-einfuehrung-fuer-haendler",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/de/blog/der-binance-grid-bot-alles-was-sie-wissen-muessen",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/de/blog/die-spot-wallet-ein-umfassender-leitfaden",
      "/en/blog/tradesanta-alternatives": "/de/blog/tradesanta-alternativen",
      "/en/blog/website-redesign": "/de/blog/website-neugestaltung",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/de/blog/was-ist-ein-ethereum-etf-und-wie-kann-man-darin-investieren",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/de/blog/was-ist-ip-allowlist-und-wie-es-ihnen-hilft-sicherer-zu-sein",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/de/blog/welches-ist-besser-dipsway-oder-3commas-fur-automatisierten-krypto-handel",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/de/blog/warum-dipsway-die-ultimative-plattform-fuer-krypto-haendler-top-wahl-fuer-anfaenger-und-profis-gleichermaßen",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/de/blog/warum-stuerzt-krypto-ab-und-wird-es-sich-in-naeherer-zukunft-erholen",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/de/blog/warum-sie-ki-in-ihrem-krypto-bot-brauchen",
      "/en/blog/wundertrading-alternatives": "/de/blog/wundertrading-alternativen",
      "/en/blog/you-re-safe-with-dipsway": "/de/blog/du-bist-sicher-mit-dipsway",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/de/blog/was-macht-einen-guten-trading-bot-gut",
      "/en/blog/will-crypto-crash": "/de/blog/wird-krypto-crash",
      "/en/blog/bitcoin-converter": "/de/blog/bitcoin-konverter",
      "/en/blog/ai-trading-bots": "/de/blog/ai-trading-bots-de",
      "/en/blog/euri-stablecoin": "/de/blog/euri-stablecoin-de",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/de/blog/handel-trump-munze-mit-krypto-bot",
      "/en/blog/trump-coin": "/de/blog/trump-muenze"
    },
    "fr": {
      "/en/blog/3commas-alternatives": "/fr/blog/3commas-alternatives-fr",
      "/en/blog/ai-crypto-bot-setup-status": "/fr/blog/etat-configuration-bot-crypto-ai",
      "/en/blog/altcoins-trading": "/fr/blog/altcoins-trading-fr",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/fr/blog/meilleures-altcoins-a-negocier-en-2024-meilleurs-choix-pour-investisseurs-crypto",
      "/en/blog/binance-bots-alternatives": "/fr/blog/binance-bots-alternatives-fr",
      "/en/blog/bitsgap-alternatives": "/fr/blog/bitsgap-alternatives-fr",
      "/en/blog/bybit-bots-alternatives": "/fr/blog/bybit-bots-alternatives-fr",
      "/en/blog/coin-and-token-difference": "/fr/blog/difference-entre-piece-et-jeton",
      "/en/blog/coinrule-alternatives": "/fr/blog/coinrule-alternatives-fr",
      "/en/blog/cornix-alternatives": "/fr/blog/cornix-alternatives-fr",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/fr/blog/crypto-candlestick-cheat-sheet-pour-traders",
      "/en/blog/cryptohopper-alternatives": "/fr/blog/cryptohopper-alternatives-fr",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/fr/blog/dipsway-et-bitget-sassocient-apprenez-a-connecter-bitget-gagnez-des-recompenses",
      "/en/blog/dipsway-new-platform": "/fr/blog/dipsway-nouvelle-plateforme",
      "/en/blog/dipsway-vs-coinrule": "/fr/blog/dipsway-vs-coinrule-fr",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/fr/blog/embracez-la-puissance-du-bot-ai-de-dipsway-pour-un-trading-crypto-reussi",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/fr/blog/explique-le-concept-de-prix-au-comptant-et-son-importance",
      "/en/blog/feature-selective-sell": "/fr/blog/sélection-de-fonctionnalités-vendre",
      "/en/blog/feature-stop-loss-&-take-profit": "/fr/blog/fonction-stop-loss-et-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/fr/blog/comment-fonctionne-un-bot-crypto",
      "/en/blog/how-to-connect-binance-api-keys": "/fr/blog/comment-connecter-cles-api-binance",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/fr/blog/comment-connecter-bingx-a-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/fr/blog/comment-connecter-cles-api-bitmart",
      "/en/blog/how-to-connect-bybit-api-keys": "/fr/blog/comment-connecter-cles-api-bybit",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/fr/blog/comment-connecter-cles-api-avancees-coinbase",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/fr/blog/comment-connecter-cles-api-legacy-kraken",
      "/en/blog/how-to-connect-kucoin-api-keys": "/fr/blog/comment-connecter-cles-api-kucoin",
      "/en/blog/how-to-connect-okx-api-keys": "/fr/blog/comment-connecter-cles-api-okx",
      "/en/blog/how-to-optimize-your-ai-bot": "/fr/blog/comment-optimiser-votre-bot-ai",
      "/en/blog/mev-bot": "/fr/blog/mev-bot-fr",
      "/en/blog/pionex-alternatives": "/fr/blog/alternatives-pionex",
      "/en/blog/risk-management-with-dipsway-bot": "/fr/blog/gestion-des-risques-avec-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/fr/blog/bot-danalyse-de-sentiment-dans-le-trading-crypto",
      "/en/blog/shrimpy-alternatives": "/fr/blog/alternatives-de-crevettes",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/fr/blog/simplifier-les-contrats-au-comptant-une-introduction-pour-les-commercants",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/fr/blog/le-bot-de-grille-binance-tout-ce-que-vous-devez-savoir",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/fr/blog/le-portefeuille-spot-un-guide-complet",
      "/en/blog/tradesanta-alternatives": "/fr/blog/alternatives-tradesanta",
      "/en/blog/website-redesign": "/fr/blog/refonte-site-web",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/fr/blog/qu-est-ce-qu-un-etf-ethereum-et-comment-investir-dans-celui-ci",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/fr/blog/qu-est-ce-qu-une-liste-blanche-d-ip-et-comment-elle-vous-aide-a-etre-plus-securise",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/fr/blog/qu-est-ce-qui-fait-qu-un-bon-bot-de-trading-est-bon",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/fr/blog/lequel-est-meilleur-dipsway-ou-3commas-pour-le-trading-crypto-automatique",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/fr/blog/pourquoi-dipsway-est-la-plateforme-ultime-pour-les-traders-crypto-choix-parfait-pour-les-debutants-et-les-pros",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/fr/blog/pourquoi-la-crypto-s-effondre-et-va-t-elle-se-redresser-dans-un-avenir-proche",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/fr/blog/pourquoi-vous-avez-besoin-d-ai-dans-votre-bot-crypto",
      "/en/blog/wundertrading-alternatives": "/fr/blog/alternatives-wundertrading",
      "/en/blog/you-re-safe-with-dipsway": "/fr/blog/vous-etes-en-securite-avec-dipsway",
      "/en/blog/will-crypto-crash": "/fr/blog/la-crypto-va-t-elle-s-effondrer",
      "/en/blog/bitcoin-converter": "/fr/blog/convertisseur-bitcoin",
      "/en/blog/ai-trading-bots": "/fr/blog/robots-de-trading-ai",
      "/en/blog/euri-stablecoin": "/fr/blog/euri-stablecoin-fr",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/fr/blog/trade-trump-coin-avec-crypto-bot",
      "/en/blog/trump-coin": "/fr/blog/trump-piece"
    },
    "tr": {
      "/en/blog/3commas-alternatives": "/tr/blog/3commas-alternatifleri",
      "/en/blog/ai-crypto-bot-setup-status": "/tr/blog/ai-kripto-bot-kurulum-durumu",
      "/en/blog/altcoins-trading": "/tr/blog/altcoins-ticaret",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/tr/blog/2024te-ticaret-icin-en-iyi-altcoinler-kripto-yatirimcilari-icin-en-iyi-secimler",
      "/en/blog/binance-bots-alternatives": "/tr/blog/binance-bot-alternatifleri",
      "/en/blog/bitsgap-alternatives": "/tr/blog/bitsgap-alternatifleri",
      "/en/blog/bybit-bots-alternatives": "/tr/blog/bybit-bot-alternatifleri",
      "/en/blog/coin-and-token-difference": "/tr/blog/coin-ve-token-farki",
      "/en/blog/coinrule-alternatives": "/tr/blog/coinrule-alternatifleri",
      "/en/blog/cornix-alternatives": "/tr/blog/cornix-alternatives-tr",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/tr/blog/kripto-mum-cubugu-kopya-kagit-tacirler-icin",
      "/en/blog/cryptohopper-alternatives": "/tr/blog/cryptohopper-alternatifleri",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/tr/blog/dipsway-ve-bitget-ortakligi-bitget-baglanmayi-ogren-odulleri-kazan",
      "/en/blog/dipsway-new-platform": "/tr/blog/dipsway-yeni-platform",
      "/en/blog/dipsway-vs-coinrule": "/tr/blog/dipsway-vs-coinrule-tr",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/tr/blog/dipswayin-ai-botu-ile-basarili-kripto-ticareti-gucunu-kucaklayin",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/tr/blog/aciklanan-spot-fiyati-kavrami-ve-onemi",
      "/en/blog/feature-selective-sell": "/tr/blog/ozellik-seleksiyonlu-satis",
      "/en/blog/feature-stop-loss-&-take-profit": "/tr/blog/ozellik-stop-loss-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/tr/blog/kripto-botu-nasil-calisir",
      "/en/blog/how-to-connect-binance-api-keys": "/tr/blog/binance-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/tr/blog/bingx-dipsway-baglama",
      "/en/blog/how-to-connect-bitmart-api-keys": "/tr/blog/bitmart-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-bybit-api-keys": "/tr/blog/bybit-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/tr/blog/coinbase-gelismis-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/tr/blog/kraken-eski-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-kucoin-api-keys": "/tr/blog/kucoin-api-anahtarlarini-baglama",
      "/en/blog/how-to-connect-okx-api-keys": "/tr/blog/okx-api-anahtarlarini-baglama",
      "/en/blog/how-to-optimize-your-ai-bot": "/tr/blog/ai-botunuzu-optimize-etme",
      "/en/blog/mev-bot": "/tr/blog/mev-bot-tr",
      "/en/blog/pionex-alternatives": "/tr/blog/pionex-alternatifleri",
      "/en/blog/risk-management-with-dipsway-bot": "/tr/blog/risk-yonetimi-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/tr/blog/duygu-analizi-botu-kripto-ticareti",
      "/en/blog/shrimpy-alternatives": "/tr/blog/karides-alternatifleri",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/tr/blog/spot-sozlesmelerini-basitlestirme-tacirler-icin-bir-giris",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/tr/blog/binance-grid-botu-her-sey-hakkinda-bilmeniz-gerekenler",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/tr/blog/spot-cuzdan-kapsamli-rehber",
      "/en/blog/tradesanta-alternatives": "/tr/blog/tradesanta-alternatifleri",
      "/en/blog/website-redesign": "/tr/blog/web-sitesi-yenileme",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/tr/blog/ethereum-etf-nedir-ve-nasil-yatirim-yapabilirsiniz",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/tr/blog/ip-izin-listesi-nedir-ve-nasil-daha-guvenli-olmaniza-yardimci-olur",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/tr/blog/iyi-bir-ticaret-botunu-iyi-yapan-nedir",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/tr/blog/dipsway-mi-3commas-mi-otomatik-kripto-ticareti-icin-hangi-daha-iyi",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/tr/blog/neden-dipsway-kripto-tacirleri-icin-nihai-platform-baslangic-ve-profesyoneller-icin-en-iyi-tercih",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/tr/blog/neden-kripto-cokuyor-ve-yakin-gelecekte-toparlanacak-mi",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/tr/blog/neden-kripto-botunuzda-yapay-zeka-olmasi-gerekiyor",
      "/en/blog/wundertrading-alternatives": "/tr/blog/wundertrading-alternatifleri",
      "/en/blog/you-re-safe-with-dipsway": "/tr/blog/sen-dipsway-ile-guvendesin",
      "/en/blog/will-crypto-crash": "/tr/blog/will-kripto-cokecek-mi",
      "/en/blog/bitcoin-converter": "/tr/blog/bitcoin-donusturucu",
      "/en/blog/ai-trading-bots": "/tr/blog/ai-ticaret-botlari",
      "/en/blog/euri-stablecoin": "/tr/blog/euri-stablecoin-tr",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/tr/blog/ticaret-trump-coin-kripto-bot-ile",
      "/en/blog/trump-coin": "/tr/blog/trump-coin-tr"
    },
    "nl": {
      "/en/blog/3commas-alternatives": "/nl/blog/3commas-alternatieven",
      "/en/blog/ai-crypto-bot-setup-status": "/nl/blog/ai-crypto-bot-opzet-status",
      "/en/blog/altcoins-trading": "/nl/blog/nl-altcoins-handel",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/nl/blog/beste-altcoins-om-te-handelen-in-2024-topkeuzes-voor-crypto-investeerders",
      "/en/blog/binance-bots-alternatives": "/nl/blog/binance-bots-alternatieven",
      "/en/blog/bitsgap-alternatives": "/nl/blog/bitsgap-alternatieven",
      "/en/blog/bybit-bots-alternatives": "/nl/blog/bybit-bots-alternatieven",
      "/en/blog/coin-and-token-difference": "/nl/blog/munt-en-token-verschil",
      "/en/blog/coinrule-alternatives": "/nl/blog/coinrule-alternatieven",
      "/en/blog/cornix-alternatives": "/nl/blog/cornix-alternatieven",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/nl/blog/crypto-kandelaar-spiekbriefje-voor-handelaars",
      "/en/blog/cryptohopper-alternatives": "/nl/blog/cryptohopper-alternatieven",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/nl/blog/dipsway-en-bitget-werken-samen-leer-hoe-je-bitget-verbindt-en-win-beloningen",
      "/en/blog/dipsway-new-platform": "/nl/blog/dipsway-nieuw-platform",
      "/en/blog/dipsway-vs-coinrule": "/nl/blog/dipsway-vs-coinrule-nl",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/nl/blog/omarm-de-kracht-van-dipsways-ai-bot-voor-succesvolle-crypto-handel",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/nl/blog/uitleg-van-het-concept-van-spotprijs-en-zijn-belang",
      "/en/blog/feature-selective-sell": "/nl/blog/feature-selectieve-verkoop",
      "/en/blog/feature-stop-loss-&-take-profit": "/nl/blog/functie-stop-loss-en-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/nl/blog/hoe-werkt-een-crypto-bot",
      "/en/blog/how-to-connect-binance-api-keys": "/nl/blog/hoe-verbind-binance-api-sleutels",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/nl/blog/hoe-bingx-verbinden-met-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/nl/blog/hoe-verbind-bitmart-api-sleutels",
      "/en/blog/how-to-connect-bybit-api-keys": "/nl/blog/hoe-verbind-je-bybit-api-sleutels",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/nl/blog/hoe-coinbase-advanced-api-sleutels-te-verbinden",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/nl/blog/hoe-kraken-legacy-api-sleutels-te-verbinden",
      "/en/blog/how-to-connect-kucoin-api-keys": "/nl/blog/hoe-kucoin-api-sleutels-verbinden",
      "/en/blog/how-to-connect-okx-api-keys": "/nl/blog/hoe-verbind-okx-api-sleutels",
      "/en/blog/how-to-optimize-your-ai-bot": "/nl/blog/hoe-optimaliseer-je-ai-bot",
      "/en/blog/mev-bot": "/nl/blog/mev-bot-nl",
      "/en/blog/pionex-alternatives": "/nl/blog/pionex-alternatieven",
      "/en/blog/risk-management-with-dipsway-bot": "/nl/blog/risicobeheer-met-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/nl/blog/sentiment-analyse-bot-in-crypto-handel",
      "/en/blog/shrimpy-alternatives": "/nl/blog/shrimpy-alternatieven",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/nl/blog/vereenvoudigen-spot-contracten-een-introductie-voor-handelaars",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/nl/blog/de-binance-grid-bot-alles-wat-je-moet-weten",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/nl/blog/de-spot-wallet-een-uitgebreide-gids",
      "/en/blog/tradesanta-alternatives": "/nl/blog/tradesanta-alternatieven",
      "/en/blog/website-redesign": "/nl/blog/website-herontwerp",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/nl/blog/wat-is-een-ethereum-etf-en-hoe-kun-je-er-in-investeren",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/nl/blog/wat-is-ip-toegangslijst-en-hoe-het-je-helpt-veiliger-te-zijn",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/nl/blog/wat-maakt-een-goede-handelsbot-goed",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/nl/blog/welke-is-beter-dipsway-of-3commas-voor-geautomatiseerde-crypto-handel",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/nl/blog/waarom-dipsway-het-ultieme-platform-voor-crypto-handelaars-topkeuze-voor-beginners-en-profs-als-gelijk",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/nl/blog/waarom-crasht-crypto-en-zal-het-herstellen-in-de-nabije-toekomst",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/nl/blog/waarom-je-ai-nodig-hebt-in-je-crypto-bot",
      "/en/blog/wundertrading-alternatives": "/nl/blog/wundertrading-alternatieven",
      "/en/blog/you-re-safe-with-dipsway": "/nl/blog/je-bent-veilig-met-dipsway",
      "/en/blog/will-crypto-crash": "/nl/blog/zal-crypto-crashen",
      "/en/blog/bitcoin-converter": "/nl/blog/bitcoin-converter-nl",
      "/en/blog/ai-trading-bots": "/nl/blog/ai-handelsbots",
      "/en/blog/euri-stablecoin": "/nl/blog/euri-stablecoin-nl",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/nl/blog/handel-trump-munt-met-crypto-bot",
      "/en/blog/trump-coin": "/nl/blog/trump-munt"
    },
    "zh": {
      "/en/blog/3commas-alternatives": "/zh/blog/3commas-tihuanpin",
      "/en/blog/ai-crypto-bot-setup-status": "/zh/blog/ai-crypto-bot-shezhi-zhuangtai",
      "/en/blog/altcoins-trading": "/zh/blog/altcoins-jiaoyi",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/zh/blog/2024年最佳交易山寨币-加密投资者的首选",
      "/en/blog/binance-bots-alternatives": "/zh/blog/binance-bots-tihuanpin",
      "/en/blog/bitsgap-alternatives": "/zh/blog/bitsgap-tihuanpin",
      "/en/blog/bybit-bots-alternatives": "/zh/blog/bybit-bots-tihuanpin",
      "/en/blog/coin-and-token-difference": "/zh/blog/bi-he-tong-zheng-de-qu-bie",
      "/en/blog/coinrule-alternatives": "/zh/blog/coinrule-tigengfangan",
      "/en/blog/cornix-alternatives": "/zh/blog/cornix-alternatives-zh",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/zh/blog/加密货币蜡烛图备忘单为交易者",
      "/en/blog/cryptohopper-alternatives": "/zh/blog/cryptohopper-tigai",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/zh/blog/dipsway-he-bitget-jiehe-xuexi-rongru-bitget-huojiangli",
      "/en/blog/dipsway-new-platform": "/zh/blog/dipsway-xin-pingtai",
      "/en/blog/dipsway-vs-coinrule": "/zh/blog/dipsway-vs-coinrule-zh",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/zh/blog/拥抱-dipsway-的-ai-机器人-成功-加密货币交易",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/zh/blog/解释现货价格及其重要性",
      "/en/blog/feature-selective-sell": "/zh/blog/功能选择性销售",
      "/en/blog/feature-stop-loss-&-take-profit": "/zh/blog/zh-feature-stop-loss-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/zh/blog/crypto-bot-如何工作",
      "/en/blog/how-to-connect-binance-api-keys": "/zh/blog/如何连接币安api密钥",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/zh/blog/如何连接bingx到dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/zh/blog/如何连接bitmartapi密钥",
      "/en/blog/how-to-connect-bybit-api-keys": "/zh/blog/如何连接bybitapikeys",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/zh/blog/如何连接coinbase高级api密钥",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/zh/blog/如何连接kraken旧版api密钥",
      "/en/blog/how-to-connect-kucoin-api-keys": "/zh/blog/如何连接库币api密钥",
      "/en/blog/how-to-connect-okx-api-keys": "/zh/blog/如何连接okxapi密钥",
      "/en/blog/how-to-optimize-your-ai-bot": "/zh/blog/优化你的ai机器人",
      "/en/blog/mev-bot": "/zh/blog/mev-bot-zh",
      "/en/blog/pionex-alternatives": "/zh/blog/pionex-tihuanpin",
      "/en/blog/risk-management-with-dipsway-bot": "/zh/blog/风险管理与dipsway机器人",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/zh/blog/情感分析机器人在加密交易",
      "/en/blog/shrimpy-alternatives": "/zh/blog/虾米替代品",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/zh/blog/简化现货合约-交易者入门",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/zh/blog/binance网格机器人一切你需要知道",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/zh/blog/spot-wallet-quan-mian-zhi-nan",
      "/en/blog/tradesanta-alternatives": "/zh/blog/jiaoyishengdan-tihuanpin",
      "/en/blog/website-redesign": "/zh/blog/网站重新设计",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/zh/blog/什么是以太坊etf以及如何投资",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/zh/blog/什么是ip允许列表及其如何帮助您更安全",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/zh/blog/什么使一个好的交易机器人变得优秀",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/zh/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading-zh",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/zh/blog/为什么-dipsway-是-终极-平台-为-加密-交易者-顶级-选择-为-初学者-和-专业人士-一样",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/zh/blog/为什么加密货币崩盘并且它会在不久的将来恢复吗",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/zh/blog/为什么你需要ai在你的加密机器人",
      "/en/blog/wundertrading-alternatives": "/zh/blog/wundertrading-tigengxuanze",
      "/en/blog/you-re-safe-with-dipsway": "/zh/blog/你-在-迪普斯威-很安全",
      "/en/blog/will-crypto-crash": "/zh/blog/will-crypto-crash-zh",
      "/en/blog/bitcoin-converter": "/zh/blog/比特币转换器",
      "/en/blog/ai-trading-bots": "/zh/blog/ai-jiao-yi-ji-qi-ren",
      "/en/blog/euri-stablecoin": "/zh/blog/euri-stablecoin-zh",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/zh/blog/交易特朗普币与加密机器人",
      "/en/blog/trump-coin": "/zh/blog/trump-coin-zh"
    },
    "pt": {
      "/en/blog/3commas-alternatives": "/pt/blog/3commas-alternativas",
      "/en/blog/ai-crypto-bot-setup-status": "/pt/blog/ai-crypto-bot-configuracao-status",
      "/en/blog/altcoins-trading": "/pt/blog/altcoins-trading-pt",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/pt/blog/melhores-altcoins-para-negociar-em-2024-principais-escolhas-para-investidores-de-cripto",
      "/en/blog/binance-bots-alternatives": "/pt/blog/alternativas-aos-bots-da-binance",
      "/en/blog/bitsgap-alternatives": "/pt/blog/bitsgap-alternativas",
      "/en/blog/bybit-bots-alternatives": "/pt/blog/alternativas-para-bots-bybit",
      "/en/blog/coin-and-token-difference": "/pt/blog/diferenca-entre-moeda-e-token",
      "/en/blog/coinrule-alternatives": "/pt/blog/alternativas-coinrule",
      "/en/blog/cornix-alternatives": "/pt/blog/alternativas-cornix",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/pt/blog/folha-de-truques-de-velas-cripto-para-comerciantes",
      "/en/blog/cryptohopper-alternatives": "/pt/blog/alternativas-cryptohopper",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/pt/blog/dipsway-e-bitget-unem-se-aprenda-como-conectar-bitget-ganhe-recompensas",
      "/en/blog/dipsway-new-platform": "/pt/blog/dipsway-nova-plataforma",
      "/en/blog/dipsway-vs-coinrule": "/pt/blog/dipsway-vs-coinrule-pt",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/pt/blog/abrace-o-poder-do-dipsway-s-ai-bot-para-negociacao-de-cripto-bem-sucedida",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/pt/blog/explicou-o-conceito-de-preco-a-vista-e-sua-significancia",
      "/en/blog/feature-selective-sell": "/pt/blog/selecao-de-recursos-venda",
      "/en/blog/feature-stop-loss-&-take-profit": "/pt/blog/recurso-stop-loss-e-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/pt/blog/como-funciona-um-bot-cripto",
      "/en/blog/how-to-connect-binance-api-keys": "/pt/blog/como-conectar-chaves-api-binance",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/pt/blog/como-conectar-bingx-ao-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/pt/blog/como-conectar-chaves-api-bitmart",
      "/en/blog/how-to-connect-bybit-api-keys": "/pt/blog/como-conectar-chaves-api-bybit",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/pt/blog/como-conectar-chaves-api-avancadas-coinbase",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/pt/blog/como-conectar-chaves-api-legado-kraken",
      "/en/blog/how-to-connect-kucoin-api-keys": "/pt/blog/como-conectar-chaves-api-kucoin",
      "/en/blog/how-to-connect-okx-api-keys": "/pt/blog/como-conectar-chaves-api-okx",
      "/en/blog/how-to-optimize-your-ai-bot": "/pt/blog/como-otimizar-seu-bot-ai",
      "/en/blog/mev-bot": "/pt/blog/mev-bot-pt",
      "/en/blog/pionex-alternatives": "/pt/blog/alternativas-pionex",
      "/en/blog/risk-management-with-dipsway-bot": "/pt/blog/gerenciamento-de-riscos-com-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/pt/blog/sentiment-analysis-bot-em-crypto-trading",
      "/en/blog/shrimpy-alternatives": "/pt/blog/alternativas-shrimpy",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/pt/blog/simplificando-contratos-spot-uma-introducao-para-traders",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/pt/blog/o-bot-de-grade-da-binance-tudo-o-que-voce-precisa-saber",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/pt/blog/a-carteira-spot-um-guia-completo",
      "/en/blog/tradesanta-alternatives": "/pt/blog/alternativas-ao-tradesanta",
      "/en/blog/website-redesign": "/pt/blog/reprojeto-do-site",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/pt/blog/oque-e-um-ethereum-etf-e-como-voce-pode-investir-nele",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/pt/blog/o-que-e-lista-de-permissao-de-ip-e-como-isso-te-ajuda-a-ser-mais-seguro",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/pt/blog/o-que-faz-um-bom-bot-de-negociacao-ser-bom",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/pt/blog/qual-e-melhor-dipsway-ou-3commas-para-negociacao-automatizada-de-criptomoedas",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/pt/blog/por-que-dipsway-e-a-ultima-plataforma-para-comerciantes-de-cripto-escolha-principal-para-iniciantes-e-profissionais-iguais",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/pt/blog/por-que-o-cripto-esta-caindo-e-vai-se-recuperar-em-breve",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/pt/blog/porque-voce-precisa-de-ia-no-seu-bot-de-cripto",
      "/en/blog/wundertrading-alternatives": "/pt/blog/alternativas-wundertrading",
      "/en/blog/you-re-safe-with-dipsway": "/pt/blog/voce-esta-seguro-com-dipsway",
      "/en/blog/will-crypto-crash": "/pt/blog/vai-cripto-quebrar",
      "/en/blog/bitcoin-converter": "/pt/blog/conversor-de-bitcoin",
      "/en/blog/ai-trading-bots": "/pt/blog/robos-de-negociacao-ai",
      "/en/blog/euri-stablecoin": "/pt/blog/euri-stablecoin-pt",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/pt/blog/comercio-trump-coin-com-cripto-bot",
      "/en/blog/trump-coin": "/pt/blog/moeda-trump"
    },
    "fil": {
      "/en/blog/3commas-alternatives": "/fil/blog/fil-3commas-alternatibo",
      "/en/blog/ai-crypto-bot-setup-status": "/fil/blog/ai-crypto-bot-setup-status-fil",
      "/en/blog/ai-trading-bots": "/fil/blog/ai-trading-bots-fil",
      "/en/blog/altcoins-trading": "/fil/blog/altcoins-trading-fil",
      "/en/blog/best-altcoins-to-trade-in-2024-top-picks-for-crypto-investors": "/fil/blog/pinakamahusay-na-altcoins-na-ipagpalit-sa-2024-pinakamahusay-na-pili-para-sa-mga-crypto-investors",
      "/en/blog/binance-bots-alternatives": "/fil/blog/mga-alternatibo-sa-binance-bots",
      "/en/blog/bitcoin-converter": "/fil/blog/bitcoin-converter-fil",
      "/en/blog/bitsgap-alternatives": "/fil/blog/bitsgap-alternatibo",
      "/en/blog/bybit-bots-alternatives": "/fil/blog/bybit-bots-alternatibo",
      "/en/blog/coin-and-token-difference": "/fil/blog/pagkakaiba-ng-coin-at-token",
      "/en/blog/coinrule-alternatives": "/fil/blog/coinrule-alternatibo",
      "/en/blog/cornix-alternatives": "/fil/blog/cornix-alternatibo",
      "/en/blog/crypto-candlestick-cheat-sheet-for-traders": "/fil/blog/crypto-candlestick-cheat-sheet-para-sa-mga-traders",
      "/en/blog/cryptohopper-alternatives": "/fil/blog/cryptohopper-alternatibo",
      "/en/blog/dipsway-and-bitget-team-up-learn-how-to-connect-bitget-win-rewards": "/fil/blog/dipsway-at-bitget-magsanib-pwersa-alamin-kung-paano-ikonekta-bitget-manalo-ng-gantimpala",
      "/en/blog/dipsway-new-platform": "/fil/blog/dipsway-bagong-platform",
      "/en/blog/dipsway-vs-coinrule": "/fil/blog/dipsway-vs-coinrule-fil",
      "/en/blog/embrace-the-power-of-dipsway-'-s-ai-bot-for-successful-crypto-trading": "/fil/blog/yakapin-ang-lakas-ng-dipsway-ai-bot-para-sa-matagumpay-na-crypto-trading",
      "/en/blog/explained-the-concept-of-spot-price-and-its-significance": "/fil/blog/ipinaliwanag-ang-konsepto-ng-spot-price-at-ang-kahalagahan-nito",
      "/en/blog/feature-selective-sell": "/fil/blog/pumili-tampok-benta",
      "/en/blog/feature-stop-loss-&-take-profit": "/fil/blog/tampok-stop-loss-take-profit",
      "/en/blog/how-does-a-crypto-bot-work": "/fil/blog/paano-gumagana-ang-isang-crypto-bot",
      "/en/blog/how-to-connect-binance-api-keys": "/fil/blog/paano-ikonekta-ang-binance-api-keys",
      "/en/blog/how-to-connect-bingx-to-dipsway": "/fil/blog/paano-ikonekta-ang-bingx-sa-dipsway",
      "/en/blog/how-to-connect-bitmart-api-keys": "/fil/blog/paano-ikonekta-bitmart-api-keys",
      "/en/blog/how-to-connect-bybit-api-keys": "/fil/blog/paano-kumonekta-bybit-api-keys",
      "/en/blog/how-to-connect-coinbase-advanced-api-keys": "/fil/blog/paano-ikonekta-coinbase-advanced-api-keys",
      "/en/blog/how-to-connect-kraken-legacy-api-keys": "/fil/blog/paano-ikonekta-kraken-legacy-api-keys",
      "/en/blog/how-to-connect-kucoin-api-keys": "/fil/blog/paano-ikonekta-ang-kucoin-api-keys",
      "/en/blog/how-to-connect-okx-api-keys": "/fil/blog/paano-ikonekta-okx-api-keys",
      "/en/blog/how-to-optimize-your-ai-bot": "/fil/blog/paano-i-optimize-ang-iyong-ai-bot",
      "/en/blog/mev-bot": "/fil/blog/mev-bot-fil",
      "/en/blog/pionex-alternatives": "/fil/blog/pionex-alternatibo",
      "/en/blog/risk-management-with-dipsway-bot": "/fil/blog/pamamahala-ng-panganib-kasama-ang-dipsway-bot",
      "/en/blog/sentiment-analysis-bot-in-crypto-trading": "/fil/blog/bot-ng-pagsusuri-ng-sentimyento-sa-pangangalakal-ng-crypto",
      "/en/blog/shrimpy-alternatives": "/fil/blog/shrimpy-alternatibo",
      "/en/blog/simplifying-spot-contracts-an-introduction-for-traders": "/fil/blog/pagpapasimple-ng-spot-kontrata-isang-panimula-para-sa-mga-mangangalakal",
      "/en/blog/the-binance-grid-bot-everything-you-need-to-know": "/fil/blog/ang-binance-grid-bot-lahat-ng-kailangan-mong-malaman",
      "/en/blog/the-spot-wallet-a-comprehensive-guide": "/fil/blog/ang-spot-wallet-isang-komprehensibong-gabay",
      "/en/blog/tradesanta-alternatives": "/fil/blog/tradesanta-alternatibo",
      "/en/blog/website-redesign": "/fil/blog/redisenyo-ng-website",
      "/en/blog/what-is-an-ethereum-etf-and-how-can-you-invest-in-it": "/fil/blog/ano-ang-ethereum-etf-at-paano-ka-makakapag-invest-dito",
      "/en/blog/what-is-ip-allowlist-and-how-it-helps-you-be-more-secure": "/fil/blog/ano-ang-ip-allowlist-at-paano-ito-nakakatulong-sa-iyo-na-maging-mas-ligtas",
      "/en/blog/what-makes-a-good-trading-bot-to-be-good": "/fil/blog/ano-ang-gumagawa-ng-isang-mabuting-trading-bot-na-maging-mabuti",
      "/en/blog/which-is-better-dipsway-or-3commas-for-automated-crypto-trading": "/fil/blog/alin-ang-mas-mainam-dipsway-o-3commas-para-sa-awtomatikong-pangangalakal-ng-crypto",
      "/en/blog/why-dipsway-is-the-ultimate-platform-for-crypto-traders-top-choice-for-beginners-and-pros-alike": "/fil/blog/bakit-dipsway-ang-pinakamahusay-na-platform-para-sa-mga-crypto-traders-pinakamahusay-na-pagpipilian-para-sa-mga-baguhan-at-pros-pareho",
      "/en/blog/why-is-crypto-crashing-and-will-it-recover-in-the-near-future": "/fil/blog/bakit-bumabagsak-ang-crypto-at-makakabawi-ba-ito-sa-malapit-na-hinaharap",
      "/en/blog/why-you-need-ai-in-your-crypto-bot": "/fil/blog/bakit-kailangan-mo-ng-ai-sa-iyong-crypto-bot",
      "/en/blog/will-crypto-crash": "/fil/blog/magkakabagsak-ba-ang-crypto",
      "/en/blog/wundertrading-alternatives": "/fil/blog/mga-alternatibo-sa-wundertrading",
      "/en/blog/you-re-safe-with-dipsway": "/fil/blog/ligtas-ka-kay-dipsway",
      "/en/blog/euri-stablecoin": "/fil/blog/euri-stablecoin-fil",
      "/en/blog/trade-trump-coin-with-crypto-bot": "/fil/blog/trade-trump-coin-gamit-crypto-bot",
      "/en/blog/trump-coin": "/fil/blog/trump-barya"
    }
  }
}