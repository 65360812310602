<script setup lang="ts">

import Callout from "../../utils/Callout.vue";
import PrimaryButton from "../../utils/PrimaryButton/PrimaryButton.vue";
import SecondaryButton from "../../utils/SecondaryButton.vue";
import autoPilotSvg from "@assets/sections/FeaturedBento/autopilot.svg?url"
import highPerformanceSvg from "@assets/sections/FeaturedBento/high-performance.svg?url"
import safeAndSecureSvg from "@assets/sections/FeaturedBento/safe-and-secure.svg?url"
import tradingViewSvg from "@assets/sections/FeaturedBento/trading-view.svg?url"

import {SOCIALS} from "@consts";
import MousemoveBorderEffect from "./MousemoveBorderEffect.vue";
import Link from "@/components/utils/Link.vue";
import useAosRefresh from "@/composables/useAosRefresh";
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});
interface Props {
  loading?: HTMLImageElement['loading']
}

const props = withDefaults(defineProps<Props>(), {
  loading: 'lazy'
})

useAosRefresh()


</script>

<template>
  <section class="w-full">
    <div
        class="grid w-full grid-cols-2 lg:grid-cols-5 lg:grid-rows-6 gap-6 container"
    >
      <!-- primary -->
      <div
          data-aos
          :class="[
          'col-span-2 row-span-2 lg:col-span-3 lg:row-span-4 group/button overflow-hidden',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5 -translate-x-5) delay-200'
        ]"
      >
        <MousemoveBorderEffect>
          <div class="flex flex-col items-center justify-between px-7.5 py-4 gap-y-37.5 w-full">
            <div
                :class="[
                'bg-effect-lighting bg-brand-gradient -z-4 opacity-70 sm:opacity-100',
                'absolute left-1/2 -translate-x-1/2 -translate-y-1/4',
                'aspect-video sm:w-full w-[150%]',
              ]"
            >
            </div>
            <div class="flex items-center flex-col">
              <Callout icon="i-mingcute:chip-fill">
                {{ t('available_at_no_extra_cost') }}
              </Callout>
              <span class="font-bold text-7 text-white mt-3 text-center">
                {{t('full_autopilot_mode')}}
              </span>
              <p
                  class="text-center text-brand-secondary max-w-141.5 mt-2.5 leading-[150%]"
              >
                {{t('full_autopilot_mode_desc')}}
              </p>
            </div>
            <a
                href="/crypto-trading-bot-earnings-calculator"
            >
              <span class="absolute inset-0"></span>
              <PrimaryButton
                  as="span"
                  size="sm"
              >
                {{ t('calculate_your_earnings_with_autopilot') }}
              </PrimaryButton>
            </a>
            <div class="absolute w-max h-40 left-1/2 bottom-12 sm:bottom-6 -translate-x-1/2 -z-5 fade-b-20">
              <img
                  :src="autoPilotSvg"
                  alt="autopilot"
                  class="h-full w-auto relative"
                  :loading="props.loading"
              />
            </div>
          </div>
        </MousemoveBorderEffect>
      </div>
      <!-- side -->
      <div
          data-aos
          :class="[
          'col-span-2 md:col-span-1 lg:col-span-2 lg:row-span-3 lg:col-start-4 group/button',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5 translate-x-5) delay-400'
        ]"
      >
        <MousemoveBorderEffect>
          <div class="py-6.5 px-7.5 w-full h-60 sm:h-72 lg:h-full overflow-hidden rounded-3 relative">
            <span class="font-bold text-5.5 text-white"> {{t('high_performance')}} </span>
            <p class="text-brand-secondary mt-2.5 leading-[150%]">
              {{ t('live_backtests_historical_performance') }}
            </p>
            <a
                href="/performance"
            >
              <span class="absolute inset-0"></span>
              <SecondaryButton
                  as="span"
                  class="mt-4 text-brand-primary"
              >
                {{t('backtests_performance')}}
              </SecondaryButton>
            </a>
            <img
                :src="highPerformanceSvg"
                alt="highPerformance"
                class="bottom-0 absolute right-0 -z-5"
                :loading="props.loading"
            />
          </div>
        </MousemoveBorderEffect>
      </div>
      <!-- extra -->
      <div
          data-aos
          :class="[
          'col-span-2 overflow-hidden md:col-span-1 lg:col-span-3 lg:(row-span-2 col-start-1 row-start-5) group/button',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5 -translate-x-5) delay-600'
        ]"
      >
        <MousemoveBorderEffect>
          <div class="w-full h-60 sm:h-72 lg:h-full px-7.5 py-6.5 flex items-baseline flex-col justify-between">
            <div>
              <span class="font-bold text-5.6 text-white">
                {{ t('test_on_simulated_wallet') }}
              </span>
              <p class="text-brand-secondary max-w-141.5 mt-2.5 leading-[150%]">
                {{ t('test_on_simulated_wallet:desc') }}
              </p>
            </div>
<!--            <a href="">-->
              <span class="absolute inset-0"></span>
            <a href="https://app.dipsway.com">
              <span class="absolute inset-0"></span>
              <SecondaryButton
                  as="span"
                  class="mt-4"
              >
                {{ t('login') }}
              </SecondaryButton>
            </a>
<!--            </a>-->
            <div
                :class="[
                '-z-5 absolute ',
                'lt-lg:(left-1/2 top-1/2 -translate-1/2)',
                'lg:(-right-4 -bottom-12)'
              ]">
              <img
                  :src="tradingViewSvg"
                  alt="tradingView"
                  class="lt-lg:opacity-5"
                  :loading="props.loading"
              />
              <!-- lighting effect start -->
              <div
                  :class="[
                  'bg-effect-lighting bg-brand-gradient opacity-70 -z-5',
                  'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                  'aspect-video w-[300%] lg:hidden',
                ]"
              />
              <!-- lighting effect end -->
            </div>
          </div>
        </MousemoveBorderEffect>
      </div>
      <!-- side -->
      <div
          data-aos
          :class="[
          'col-span-2 overflow-hidden lg:col-span-2 lg:row-span-3 lg:col-start-4 group/button',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5 translate-x-5) delay-400'
        ]"
      >
        <MousemoveBorderEffect>
          <div class="w-full h-80 sm:h-72 lg:h-full px-7.5 py-6.5 flex items-baseline flex-col justify-between">
            <div>
              <span class="font-bold text-5.6 text-white"> {{ t('safe_and_secure') }} </span>
              <p class="text-brand-secondary mt-2.5 leading-[150%]">
                {{t('safe_and_secure_desc')}}
              </p>
            </div>
            <a href="#safe-and-secure">
              <span class="absolute inset-0"></span>
              <SecondaryButton
                  as="span"
                  class="mt-4"
              >
                {{ t('dipsway') }} {{ t('security') }}
              </SecondaryButton>
            </a>
            <img
                :src="safeAndSecureSvg"
                alt="safeAndSecurity"
                class="-bottom-4 absolute left-1/2 -translate-x-1/2 -z-5 h-40 w-auto"
                :loading="props.loading"
            />
          </div>
        </MousemoveBorderEffect>
      </div>
    </div>
    <div
        data-aos
        class="mt-7 leading-[150%] text-center text-brand-secondary duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-700"
    >
      {{ t('follow_dipsway_development') }}
      <Link
          :href="SOCIALS.telegram.href"
          class="text-brand-primary"
      >
        {{ t('join_us_on_telegram') }}
      </Link>
    </div>
  </section>

</template>